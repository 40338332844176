<template>
    <Popup title="Forgot Password" :other-parts="[LoginPopupPart, HelpPopupPart]">
	    <form class="flex flex-col gap-4">
            <FormGroup
                    v-model:parent_ref="email"
                    label="Email"
                    type="email"
                    placeholder="Email"
                    :check="(value) => {
                        return value.length <= fieldLimits.email.max && /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value);
                    }"
                    autocomplete="email"
            />
		    <HCaptcha />
	        <div class="bg-gray-200 w-full h-px"></div>
	        <PopupsComponentsPopupButton
			        text="Send Email"
			        :action="forgotPassword"
			        :arrow="null"
	        />
        </form>
    </Popup>
</template>

<script setup>
import {usePopup} from "~/composables/usePopup";
import {notify} from "@kyvg/vue3-notification";
import HelpPopupPart from '~/components/popups/parts/HelpPopupPart.vue';
import LoginPopupPart from '~/components/popups/parts/LoginPopupPart.vue';

const {setPopup} = usePopup();

const email = ref("");

async function forgotPassword() {
    notify({
        type: "info",
        title: "Sending email..."
    })

	const iframe = document.querySelector(".captcha iframe");
	if (!iframe) {
		notify({
			type: "error",
			title: "Please complete the captcha!"
		})
		return;
	}

	const captchaResponse = iframe.getAttribute("data-hcaptcha-response");

	if (!captchaResponse) {
		notify({
			type: "error",
			title: "Please complete the captcha!"
		})
		return;
	}

    const response = await $fetch("/api/user/forgot", {
        method: "POST",
        body: {
            email: email.value,
	        captcha: captchaResponse
        },
        ignoreResponseError: true,
    });

    if (response.status) {
        notify({
            type: "success",
            title: "Email sent"
        })
        setPopup('LoginPopup');
    } else if (response.error) {
	    notify({
		    type: "error",
		    title: response.error
	    })
    } else {
        notify({
            type: "error",
            title: "Error occurred while sending email"
        })
    }
}
</script>